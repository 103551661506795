import React, { useEffect, useState } from "react";
import { getVersionDetailsData } from "../services/hc_apis";

function Coach() {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    getVersionDetails();
  }, []);

  const getVersionDetails = async () => {
    const result = await getVersionDetailsData();
    if (result.status === 200) {
      setVersion(result?.data?.data);
    } else {
      setVersion(null);
    }
  };

  return (
    <>
      <header className="site_header" data-sticky-shrink="false">
        <a href="/" className="logo">
          <img
            src="assets/images/HabitCircles/hc_coach_logo.svg"
            alt="Coach Logo"
            className="logo"
          />
        </a>
        <div className="header_menu_item">
          <a className="header_menu_item_link" href="">
            <img
              src="assets/images/shared/icn_email.svg"
              className="waving_"
              width="20"
              height="20"
              style={{ marginRight: "10px" }}
            />
            WRITE TO US
          </a>
        </div>
      </header>
      <section className="billionsuns_banner_container">
        <img
          src="assets/images/HabitCircles/hc_banner.png"
          className="billionsuns_banner_container_image"
        />
        <img
          src="assets/images/HabitCircles/hc_banner_mobile.png"
          className="billionsuns_banner_container_image_mobile"
        />
        <div className="banner_download_section">
          <a className="hc_download_android_btn" href={version?.apk_url}>
            <img
              src="assets/images/HabitCircles/android_robot.svg"
              className="waving_"
              width="20"
              height="20"
              style={{ marginRight: "10px" }}
            />
            DOWNLOAD HABITCIRCLES
          </a>
          <div className="hc_download_android_btn_text">
            Available for Android now, iOS version coming soon!
          </div>
        </div>
      </section>
      <section className="hc_coach_usage_container">
        <div className="hc_coach_row">
          <div className="hc_coach_cell" style={{ backgroundColor: "#AFA5DC" }}>
            <div className="hc_coach_cell_content">
              <p>Boost Member Engagement</p>
              <p className="hc_coach_cell_content_subheading">
                Increase the engagement satisfaction by constantly monitoring
                and assisting them.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_2.png" />
          </div>
          <div className="hc_coach_cell" style={{ backgroundColor: "#AA69C3" }}>
            <div className="hc_coach_cell_content">
              <p>Build Stronger Relationships</p>
              <p className="hc_coach_cell_content_subheading">
                Enable long-term self transformation by empowering your members.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_3.png" />
          </div>
        </div>
        <div className="hc_coach_row">
          <div className="hc_coach_cell" style={{ backgroundColor: "#F694C5" }}>
            <div className="hc_coach_cell_content">
              <p>Spread your Brand Organically</p>
              <p className="hc_coach_cell_content_subheading">
                Cultivate genuine connections and let your brand resonate
                organically with your members.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_4.png" />
          </div>
          <div className="hc_coach_cell" style={{ backgroundColor: "#D63296" }}>
            <div className="hc_coach_cell_content">
              <p>Grow your Network Effortlessly</p>
              <p className="hc_coach_cell_content_subheading">
                Attract new members through testified expressions and validation
                of your audience.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_1.png" />
          </div>
        </div>
      </section>
      <section className="hc_coach_usage_container_mobile">
        <div className="hc_coach_row_mobile">
          <div className="hc_coach_cell" style={{ backgroundColor: "#AFA5DC" }}>
            <div className="hc_coach_cell_content">
              <p>Boost Member Engagement</p>
              <p className="hc_coach_cell_content_subheading">
                Increase the engagement satisfaction  by constantly monitoring
                and assisting them.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_2.png" />
          </div>
          <div className="hc_coach_cell" style={{ backgroundColor: "#AA69C3" }}>
            <div className="hc_coach_cell_content">
              <p>Build Stronger Relationships</p>
              <p className="hc_coach_cell_content_subheading">
                Enable long-term self transformation by empowering your members.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_3.png" />
          </div>
        </div>
        <div className="hc_coach_row">
          <div className="hc_coach_cell" style={{ backgroundColor: "#F694C5" }}>
            <div className="hc_coach_cell_content">
              <p>Spread your Brand Organically</p>
              <p className="hc_coach_cell_content_subheading">
                Cultivate genuine connections and let your brand resonate
                organically with your members.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_4.png" />
          </div>
          <div className="hc_coach_cell" style={{ backgroundColor: "#D63296" }}>
            <div className="hc_coach_cell_content">
              <p>Grow your Network Effortlessly</p>
              <p className="hc_coach_cell_content_subheading">
                Attract new members through testified expressions and validation
                of your audience.
              </p>
            </div>
            <img src="assets/images/HabitCircles/hc_coach_illusion_1.png" />
          </div>
        </div>
      </section>
      <section className="hc_coach_dashboard_container">
        <h3 className="hc_coach_dashboard_text">
          Effectively monitor your student's progress through the dashboard
        </h3>
        <img src="assets/images/HabitCircles/dashboard_mac.svg" />
      </section>
      <section>
        <footer className="footer" style={{ marginTop: "0px" }}>
          <div className="links_container">
            <div className="contact_us_link_container">
              <h3 className="links_container_heading">CONTACT US</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "20px",
                }}
              >
                <img src="assets/images/shared/icn_address.svg" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "25px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "-3px",
                  }}
                >
                  <span className="link_container_links_text">
                    H No.13-14, 2nd floor, Kothnur Main Rd,
                  </span>
                  <span className="link_container_links_text">
                    Opposite Corporation Bank,
                  </span>
                  <span className="link_container_links_text">
                    JP Nagar 7th Phase, Bengaluru,
                  </span>
                  <span className="link_container_links_text">
                    Karnataka - 560078
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "10px",
                }}
              >
                <img src="assets/images/shared/icn_email.svg" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "25px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "-3px",
                  }}
                >
                  <span className="link_container_links_text">
                    hello@heartfultech.com
                  </span>
                </div>
              </div>
            </div>
            <div className="contact_us_link_container policy_link_container">
              <h3 className="links_container_heading">LINKS</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "20px",
                }}
              >
                <div
                  data-toggle="modal"
                  data-target="#serviceTermsModal"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <span
                    className="link_container_links_text"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Terms and Conditions
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "10px",
                }}
              >
                <div
                  data-toggle="modal"
                  data-target="#privacyPolicyModal"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <span
                    className="link_container_links_text"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Privacy Policy
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "10px",
                }}
              >
                <div
                  data-toggle="modal"
                  data-target="#refundPolicyModal"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <span
                    className="link_container_links_text"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Refunds / Cancellation
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ht_logo_container">
            <img src="assets/images/shared/HT_logo.svg" />
          </div>
        </footer>
      </section>
    </>
  );
}

export default Coach;
